import Button from "components/controls/button"
import Metadata from "components/utils/metadata/metadata"
import BreakpointContext from "context/breakpointContext"
import {
  StoreCountries,
  STORE_COUNTRY,
  useCountries,
  useCountriesDispatcher,
} from "context/countryContext"
import { Screen, useSetScreen } from "context/screenContext"
import { navigate } from "gatsby"
import { size } from "helpers/responsiveSize"
import { useNavBar } from "hooks/useNavBar"
import FlagUKRound from "images/flags/flag-uk-round.svg"
import FlagUSARound from "images/flags/flag-usa-round.svg"
import CleanLayout from "layouts/cleanLayout"
import React, { useContext } from "react"
import routes from "services/routes"

/**
 * Store chooser screen
 */
const StoreChooser = ({ location }) => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Don't Use the navbar on this page
  useNavBar({
    hasNavBar: false,
  })

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Get the country of origin from the context
  const { countryOfOrigin } = useCountries()

  // Dispatcher for countries context
  const countriesDispatcher = useCountriesDispatcher()

  /**
   * Set the Store country and navigate to it.
   *
   * @param {*} storeCountry The Store Country
   */
  const setStoreCountry = (country) => {
    // Set store country for this session
    countriesDispatcher({
      type: STORE_COUNTRY,
      payload: {
        storeCountry: country.storeCountry,
        storeURL: country.storeURL,
      },
    })

    // Navigate to the store
    navigate(routes.STORE(country.storeURL))
  }

  return (
    <>
      <Metadata title="Choose Store" pathname={location.pathname} />

      <CleanLayout>
        <div className="flex flex-col items-center">
          <p
            style={{
              fontSize: size(bpt, "7.7vw"),
            }}
            className="
              text-center
              tablet:text-4xl laptop:text-4.5xl
              font-bold"
          >
            Choose a store
          </p>

          {countryOfOrigin === undefined && (
            <p
              style={{
                fontSize: size(bpt, "4vw"),
                marginTop: size(bpt, "3vw"),
              }}
              className="
                tablet:pt-6
                tablet:text-base laptop:text-lg
                text-center
                tablet:max-w-lg"
            >
              We couldn't detect your region; do you have an ad-blocker running?
            </p>
          )}

          {countryOfOrigin !== undefined && (
            <div
              style={{
                fontSize: size(bpt, "3.7vw"),
                marginTop: size(bpt, "3vw"),
              }}
              className="
                  tablet:pt-6
                  tablet:text-base laptop:text-base
                  text-center
                  tablet:max-w-lg"
            >
              <p className="font-medium">
                Superfonik isn't available in your region yet!
              </p>
              <p
                style={{
                  marginTop: size(bpt, "3vw"),
                }}
                className="mt-2"
              >
                You can still browse any of our stores, however some content
                will be unavailable for purchase.
              </p>
            </div>
          )}
        </div>

        <div
          style={{
            paddingTop: size(bpt, "9vw"),
          }}
          className="
            col-start-1 col-span-full 
            tablet:pt-12 laptop:pt-9
            tablet:px-14 laptop:px-28 desktop:px-60
            flex justify-center"
        >
          <div
            className="
              flex 
              flex-col 
              items-center
              tablet:mb-3.5 laptop:mb-5"
          >
            <Button
              icon={<FlagUKRound className="w-full h-full" />}
              keyline={true}
              style={{
                width: size(bpt, "34vw"),
                height: size(bpt, "34vw"),
                marginLeft: size(bpt, "4vw"),
                marginRight: size(bpt, "4vw"),
              }}
              className="
                tablet:w-30 laptop:w-36
                tablet:h-30 laptop:h-36
                tablet:mx-4"
              onClick={() => {
                setStoreCountry(StoreCountries.GB)
              }}
            />
            <p
              style={{
                marginTop: size(bpt, "2.5vw"),
                fontSize: size(bpt, "4vw"),
              }}
              className="
                tablet:mt-3.5 laptop:mt-3
                tablet:text-base laptop:text-lg
                tracking-tight laptop:tracking-wide
                text-grey-dark
                font-medium"
            >
              United Kingdom
            </p>
          </div>

          <div
            className="
              flex 
              flex-col 
              items-center
              tablet:mb-3.5 laptop:mb-5"
          >
            <Button
              icon={<FlagUSARound className="w-full h-full" />}
              keyline={true}
              disabled={true}
              style={{
                width: size(bpt, "34vw"),
                height: size(bpt, "34vw"),
                marginLeft: size(bpt, "4vw"),
                marginRight: size(bpt, "4vw"),
              }}
              className="
                tablet:w-30 laptop:w-36
                tablet:h-30 laptop:h-36
                tablet:mx-4"
              // TODO: Enable on store go-live!
              // onClick={() => {
              //   setStoreCountry(StoreCountries.US)
              // }}
            />
            <p
              style={{
                marginTop: size(bpt, "2.5vw"),
                fontSize: size(bpt, "4vw"),
              }}
              className="
                tablet:mt-3.5 laptop:mt-3
                tablet:text-base laptop:text-lg
                tracking-tight laptop:tracking-wide
                text-grey-dark
                font-medium"
            >
              United States
            </p>
            <p
              style={{
                fontSize: size(bpt, "3vw"),
              }}
              className="
                tablet:text-xs laptop:text-sm
                tracking-tight laptop:tracking-wide
                text-red"
            >
              Coming Q3 22!
            </p>
          </div>
        </div>
      </CleanLayout>
    </>
  )
}

export default StoreChooser
StoreChooser.whyDidYouRender = true
